  import { Component, Inject, OnInit } from '@angular/core';
  import { BackendService } from 'src/app/services/backend.service';
  import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
  import { DomSanitizer } from '@angular/platform-browser';
  import { ToastrService } from 'ngx-toastr';
  import { take } from 'rxjs/operators';
  import { UiService } from 'src/app/services/ui.service';
  import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { BehaviorSubject } from 'rxjs';

  @Component({
    selector: 'app-email-template',
    templateUrl: './email-template.component.html',
    styleUrls: ['./email-template.component.scss']
  })
  export class EmailTemplateComponent implements OnInit {
    projectUsers=[];
    selectedUsers: string[] = [];
    constructor(public backendService: BackendService,
      private sanitizer:DomSanitizer,
      private uiService:UiService,
      private toastr : ToastrService,
      public dialogRef: MatDialogRef<EmailTemplateComponent>,
      public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    const projectPeoples = this.uiService.project?.people;
    const keys = Object.keys(projectPeoples);
    this.backendService.getProjectPeople(keys).then(users => {
      this.projectUsers = users;
    });
  }

  separatorKeysCodes: number[] = [ENTER, COMMA]; // Press Enter or Comma to add emails
  selectedEmails: string[] = []; // Stores manually added emails

  // Add manually entered emails (supports comma-separated input)
  addEmails(event: any): void {
    const input = event.input;
    const value = event.value.trim();

    if (value) {
      const emails = value.split(',').map(email => email.trim()); // Split by comma & trim spaces
      emails.forEach(email => {
        if (this.validateEmail(email) && !this.selectedEmails.includes(email)) {
          this.selectedEmails.push(email);
        }
      });
    }

    if (input) {
      input.value = ''; // Clear input field after adding emails
    }
  }

  // Remove an email
  removeEmail(email: string): void {
    this.selectedEmails = this.selectedEmails.filter(e => e !== email);
  }

  // Validate email format
  validateEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  public isEmailLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  sendEmail(){
    let data =  {
     id:this.data.reportId,
     project_member_ids:this.selectedUsers,
     non_member_emails: this.selectedEmails
    }
    this.isEmailLoading$.next(true);
     this.backendService.updateProjectDailyReports$(data, this.data.projectId).then(() => {
      this.toastr.success('Email sent successfully');
      this.backendService.sendEmailDailyReport(this.data.reportId,this.data.projectId).subscribe();
      this.dialogRef.close();
      this.isEmailLoading$.next(false);
        },error=>{
          this.isEmailLoading$.next(false);
        })

  }
}
