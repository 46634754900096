<div class="popup-header w-100 d-flex">
    <p class="w-100">
     Template Contents
    </p>
    <button matTooltip="Save" (click)="saveTemplate()"  mat-raised-button  color="primary" class="dark">
        <mat-icon>save</mat-icon>
    </button>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>

<div class="grid-container bg full h-100 position-relative px-3 pt-0">

    <div class="editor-container editor-styles">
   <div id="dailyReportEditor"  contenteditable="true"></div>
    </div>
    </div>