import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BackendService } from '../services/backend.service';
import { UiService } from '../services/ui.service';
import { DailyReportsInitialsComponent } from './daily-reports-initials/daily-reports-initials.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { ReportHtmlViewerComponent } from './report-html-viewer/report-html-viewer.component';
@Component({
  selector: 'app-daily-reports',
  templateUrl: './daily-reports.component.html',
  styleUrls: ['./daily-reports.component.scss']
})
export class DailyReportsComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject();
  dailyReports = [];
  constructor(
    public backendService: BackendService,
    public uiService: UiService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DailyReportsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getDailyReport();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getDailyReport() {
    this.backendService.getDailyReports$(this.data.selectedProjectId).pipe(takeUntil(this.onDestroy$)).subscribe((reports: any[]) => {
      const report = [];
      reports = reports.sort((a, b) => {
        if (a.createdTime?.seconds === b.createdTime?.seconds) {
          return b.createdTime?.nanoseconds - a.createdTime?.nanoseconds; // Descending nanoseconds
        }
        return b.createdTime?.seconds - a.createdTime?.seconds; // Descending seconds
      });
      const userId = this.backendService.currentUserData?.uid;
      const projectPeoples = this.uiService.project?.people;
      const keys = Object.keys(projectPeoples);
      const key = keys.find(key => key == userId);
      reports.forEach(rp => {
        if (key) {
          if (projectPeoples[key].role == 'owner' ||
            (projectPeoples[key].role == 'editor' && projectPeoples[key].dailyReport == true)
          ) {
            report.push(rp);
          }
          else if (rp.sendForReview && rp.reviewer === userId) {
            report.push(rp);
          }
          else if (rp.isReviewed && rp.approver === userId) {
            report.push(rp);
          }
        }
      });
      this.dailyReports = report;
    })
  }

  newReport() {
    const data = {
      assetId: this.data.assetId,
      isAssetOwner: this.data.isAssetOwner,
      asset: this.uiService.asset,
      projectId: this.data.selectedProjectId,
      reports: this.dailyReports
    };
    this.dialog.open(DailyReportsInitialsComponent, {
      panelClass: 'no-border-radius-dialog',
      width: '50%',
      height: '100%',
      data,
    });

  }

  editReport(report) {
    const data = {
      assetId: this.data.assetId,
      isAssetOwner: this.data.isAssetOwner,
      asset: this.uiService.asset,
      projectId: this.data.selectedProjectId,
      id: report.id,

    };
    this.dialog.open(DailyReportsInitialsComponent, {
      panelClass: 'no-border-radius-dialog',
      width: '50%',
      height: '100%',
      data,
    });
  }

  deleteReport(report) {
    this.backendService.deleteDailyReport(this.data.selectedProjectId, report.id).then();
  }

  onSubmit() { }

  reportView(report) {
    this.backendService.getDailyReportTemplateById(this.data.selectedProjectId, report.templateId).pipe(takeUntil(this.onDestroy$)).subscribe((template) => {
      // this.backendService.getFile(template.templateUrl).subscribe((contents: any) => {
      const data = {
        html: template.htmlContent,
        reportData: report,
        projectId: this.data.selectedProjectId
      }
      this.dialog.open(ReportHtmlViewerComponent, {
        panelClass: 'no-border-radius-dialog',
        height: '90%',
        data,
      });
    });
    //   });
  }

  downloadPdf(url: string,): void {
    window.open(url);
  }

  downloadCSV(url: string): void {
    window.open(url);
  }

  emailReport(report) {
    const data = {
      reportId:report.id,
      projectId: this.data.selectedProjectId
    }
    this.dialog.open(EmailTemplateComponent, {
      panelClass: 'no-border-radius-dialog',
      height: '50%',
      width:'50%',
      data,
    });
    //this.backendService.createDailyReport(report.id, this.data.selectedProjectId).subscribe()
  }

  dailyReportAccess() {
    const userId = this.backendService.currentUserData?.uid;
    const projectPeoples = this.uiService.project?.people;
    const keys = Object.keys(projectPeoples);
    const key = keys.find(key => key == userId);
    if (key) {
      if (projectPeoples[key].role == 'owner' ||
        (projectPeoples[key].role == 'editor' && projectPeoples[key].dailyReport == true)
      ) {
        return false;
      }
    }
    return true;
  }

  showReportIcon(report) {
    const userId = this.backendService.currentUserData?.uid;
    if (!report.isReviewed && !report.sendForReview) {
      const projectPeoples = this.uiService.project?.people;
      const keys = Object.keys(projectPeoples);
      const key = keys.find(key => key == userId);
      if (key) {
        if (projectPeoples[key].role == 'owner' ||
          (projectPeoples[key].role == 'editor' && projectPeoples[key].dailyReport == true)
        ) {
          return true;
        }
      }
    }

    if (report?.isReviewed && !report?.isApproved && report.approver === userId) {
      return true;
    }
    if (
      report?.sendForReview && !report?.isApproved && report.reviewer === userId
      && !report?.isReviewed
    ) {
      return true;
    }

    return false;
  }

  sendForReview(report) {
    this.backendService.updateProjectDailyReports$(
      {
        reportLocation: {
          status: 'Sent for review' // This updates or adds the 'status' field
        }, sendForReview: true, id: report.id
      }, this.data.selectedProjectId).then(() => {
        this.toastr.success('Reprot send for review');
        this.backendService.getUser(report.reviewer).pipe(take(1)).subscribe(user => {
          if (user)
            this.backendService.dailyReportReviewEmail(user.data().email, report.reportNumber, this.uiService.project?.name).subscribe();
        })
      })
  }
}
