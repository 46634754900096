import { Component, ViewChild, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { ITreeNode, TreeNode, TreeNodeType } from '../../models/tree-node.interface';
import { BackendService } from 'src/app/services/backend.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-template-adjustment',
  templateUrl: './template-adjustment.component.html',
  styleUrls: ['./template-adjustment.component.scss']
})
export class TemplateAdjustmentComponent implements OnInit {
  content:any;
  constructor(private backendService:BackendService,
    private toastr:ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TemplateAdjustmentComponent>,
    @Inject(DOCUMENT) private document: Document,
    ) { }

  ngOnInit(): void {
    setTimeout(() => this.initializeSummernote(this.data.htmlContent), 20)
  }
  

 // Initialize Summernote for the specified editor
 initializeSummernote(content) {
  const editorElement = $(`#dailyReportEditor`);
  editorElement.summernote({
    height: 300,
    focus: true,
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['superscript', 'subscript','fontname']],
      ['fontsize', ['fontsize']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['insert', ['link', 'picture', 'table']],
      ['view', ['fullscreen', 'codeview']]
    ],
    fontNames: [   'Arial', 'Arial Black', 'Calibri', 'Cambria', 'Candara', 'Comic Sans MS',
    'Consolas', 'Constantia', 'Corbel', 'Courier New', 'Georgia', 'Helvetica',
    'Impact', 'Lucida Bright', 'Lucida Console', 'Lucida Sans Unicode',
    'Palatino Linotype', 'Segoe UI', 'Tahoma', 'Times New Roman',
    'Trebuchet MS', 'Verdana'], // Define custom fonts
    callbacks: {
      onChange: (contents: any) => {
         // Convert the table class to inline styles
      // const html = this.convertTableClassesToInlineStyles(contents);
        this.content = contents; // Update the content in the editors array
      }
    }
  });

  // Set initial value for existing content
  editorElement.summernote('code', content);
}

convertTableClassesToInlineStyles(htmlContent: string): string {
  const classToStyleMap = {
    'table': 'width: 100%; border-collapse: collapse;',
    'table-bordered': 'border: 1px solid #ddd;',
    'table-striped': 'background-color: #f9f9f9;',
    'table-condensed': 'padding: 4px;',
    'table-hover': 'cursor: pointer;',
  };

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');

  // Get all tables and apply styles
  const tables = doc.querySelectorAll('table');
  tables.forEach(table => {
    const classList = Array.from(table.classList);
    classList.forEach(className => {
      if (classToStyleMap[className]) {
        table.style.cssText += classToStyleMap[className];
        table.classList.remove(className);
      }
    });

    const rows = table.querySelectorAll('tr');
    rows.forEach(row => {
      row.style.borderBottom = '1px solid #ddd';
    });

    const cells: any = table.querySelectorAll('td, th');
    cells.forEach(cell => {
      cell.style.borderRight = '1px solid #ddd';
      cell.style.padding = '8px';
    });

    const brTags = table.querySelectorAll('br');
    brTags.forEach(br => {
      const nbsp = document.createTextNode(' ');
      br.replaceWith(nbsp);
    });

    if (classList.includes('table-striped')) {
      rows.forEach((row, index) => {
        if (index % 2 === 0) {
          row.style.backgroundColor = '#f9f9f9';
        }
      });
    }
  });

  // Convert all H1-H7 tags to DIV
  for (let i = 1; i <= 7; i++) {
    const headingTags = doc.querySelectorAll(`h${i}`);
    headingTags.forEach(heading => {
      const div = document.createElement('div');
      div.innerHTML = heading.innerHTML;

      // Apply equivalent inline styles
      div.style.fontSize = `${2 - (i - 1) * 0.25}em`; // Scales font size based on the level
      div.style.fontWeight = 'bold';
      div.style.marginTop = `${0.67 - (i - 1) * 0.1}em`; // Adjust margins slightly based on level
      div.style.marginBottom = `${0.67 - (i - 1) * 0.1}em`;
      div.style.lineHeight = '1.2';

      heading.replaceWith(div);
    });
  }

  // Convert <font> tags to inline styles
  const fontTags = doc.querySelectorAll('font');
  fontTags.forEach(font => {
    const span = document.createElement('span');
    span.innerHTML = font.innerHTML;

    // Apply styles from <font> attributes
    if (font.hasAttribute('size')) {
      const size = font.getAttribute('size');
      const fontSizeMap = {
        '1': '0.8em',
        '2': '1em',
        '3': '1.2em',
        '4': '1.4em',
        '5': '1.6em',
        '6': '1.8em',
        '7': '2em',
      };
      span.style.fontSize = fontSizeMap[size] || '1em';
    }
    if (font.hasAttribute('color')) {
      span.style.color = font.getAttribute('color')!;
    }
    if (font.hasAttribute('face')) {
      span.style.fontFamily = font.getAttribute('face')!;
    }

    font.replaceWith(span);
  });

  return doc.body.innerHTML;
}

saveTemplate(){
  this.backendService.updateDailyReportTemplate(this.data.projectId,this.data.templateId, this.content).subscribe(() => {
    this.dialogRef.close();
    this.toastr.success("template updated")
  })
}
}
