<div class="form-container w-100 mt-2">
    <div style="margin:10px;">
        <button mat-raised-button type="submit" color="primary" class="submit-button update-initials-button"
            [disabled]="(isUpdateInitialsLoading$ | async)   || (!initialsForm.valid)"
            (click)="updateProjectDailyReport()">
            <!-- (click)="openEditor()" -->
            <span *ngIf="!(isUpdateInitialsLoading$ | async)" class="text-uppercase">{{!data?.id ? 'Create' :
                'Update'}}</span>
            <span *ngIf="isUpdateInitialsLoading$ | async">
                <mat-spinner [diameter]="20"></mat-spinner>
            </span>
        </button>
       <mat-icon matTooltip="Copy project data from last report" class="copy text-right" *ngIf="data.reports?.length && !data?.id" (click)="copy()">file_copy
        </mat-icon>
        <mat-icon class="ml-2 close-btn text-right" (click)="dialogRef.close()">close</mat-icon>
    </div>

    <mat-tab-group class="h-100 w-100">
        <mat-tab label="Project">
            <form class="p-2 m-2 card bg-white form-border" [formGroup]="initialsForm"
                style="position: relative;">
                <!-- Tempalte -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="reportNumber" [class.form-field__label_full]="reportNumber.value"
                        class="form-field__label required">
                        Choose Template
                    </label>
                    <mat-select required class="form-control input-field report-name-input form-field__input" required
                        formControlName="templateId">
                        <mat-option *ngFor="let template of templates" [value]="template.id">
                            {{template.title}}
                        </mat-option>
                    </mat-select>

                </div>

                <!-- Report Number -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="reportNumber" [class.form-field__label_full]="reportNumber.value"
                        class="form-field__label required">
                        Report Number
                    </label>
                    <input formControlName="reportNumber" type="text" required
                        class="form-control input-field form-field__input" name="reportNumber" />
                </div>

                <!-- Project Name -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="projectName" [class.form-field__label_full]="projectName.value"
                        class="form-field__label required">
                        Project Name
                    </label>
                    <input formControlName="projectName" type="text" required
                        class="form-control input-field form-field__input" name="projectName" />
                </div>

                <!-- Project Location -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="projectLocation" [class.form-field__label_full]="projectLocation.value"
                        class="form-field__label">
                        Project Location
                    </label>
                    <input formControlName="projectLocation" type="text"
                        class="form-control input-field form-field__input" name="projectLocation" />
                </div>

                <!-- Report Date -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="reportDate" [class.form-field__label_full]="reportDate.value" class="form-field__label">
                        Report Date
                    </label>
                    <input [max]="maxDate" formControlName="reportDate" type="date" required
                        class="form-control input-field form-field__input" name="reportDate" />
                </div>

                <!-- Client Name -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="clientName" [class.form-field__label_full]="clientName.value" class="form-field__label">
                        Client Name
                    </label>
                    <input formControlName="clientName" type="text" class="form-control input-field form-field__input"
                        name="clientName" />
                </div>

                <!-- Project Description -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="projectDescription" [class.form-field__label_full]="projectDescription.value"
                        class="form-field__label">
                        Project Description
                    </label>
                    <textarea formControlName="projectDescription" class="form-control input-field form-field__input"
                        name="projectDescription"></textarea>
                </div>

                <!-- Revision -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="revision" [class.form-field__label_full]="revision.value" class="form-field__label">
                        Revision
                    </label>
                    <input formControlName="revision" type="text" class="form-control input-field form-field__input"
                        name="revision" />
                </div>

                <!-- Approver -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="reviewer" [class.form-field__label_full]="reviewer.value"
                        class="form-field__label required">
                        Reviewer
                    </label>

                    <mat-select required class="form-control input-field report-name-input form-field__input" required
                        formControlName="reviewer">
                        <mat-option *ngFor="let member of reviewerMembers" [value]="member.id">
                            {{member.name}}
                        </mat-option>
                    </mat-select>
                </div>

                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="approver" [class.form-field__label_full]="approver.value"
                        class="form-field__label  required">
                        Approver
                    </label>
                    <mat-select required class="form-control input-field report-name-input form-field__input" required
                        formControlName="approver">
                        <mat-option *ngFor="let member of approverMembers" [value]="member.id">
                            {{member.name}}
                        </mat-option>
                    </mat-select>
                </div>

                <!-- Work Permit Number -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="workPermitNo" [class.form-field__label_full]="workPermitNo.value"
                        class="form-field__label">
                        Work Permit Number
                    </label>
                    <input formControlName="workPermitNo" type="text" class="form-control input-field form-field__input"
                        name="workPermitNo" />
                </div>

                <!-- Work Site -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="workSite" [class.form-field__label_full]="workSite.value" class="form-field__label">
                        Work Site
                    </label>
                    <input formControlName="workSite" type="text" class="form-control input-field form-field__input"
                        name="workSite" />
                </div>

                <!-- Location -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="location" [class.form-field__label_full]="location.value" class="form-field__label">
                        Location
                    </label>
                    <input formControlName="location" type="text" class="form-control input-field form-field__input"
                        name="location" />
                </div>

                <!-- Personnel Name -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="personnelName" [class.form-field__label_full]="personnelName.value"
                        class="form-field__label">
                        Personnel Name
                    </label>
                    <input formControlName="personnelName" type="text"
                        class="form-control input-field form-field__input" name="personnelName" />
                </div>

                <!-- Personnel Company -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="personnelCompany" [class.form-field__label_full]="personnelCompany.value"
                        class="form-field__label">
                        Personnel Company
                    </label>
                    <input formControlName="personnelCompany" type="text"
                        class="form-control input-field form-field__input" name="personnelCompany" />
                </div>

                <!-- Position -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="position" [class.form-field__label_full]="position.value" class="form-field__label">
                        Position
                    </label>
                    <input formControlName="position" type="text" class="form-control input-field form-field__input"
                        name="position" />
                </div>

                <!-- Comments -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="comments" [class.form-field__label_full]="comments.value" class="form-field__label">
                        Comments
                    </label>
                    <textarea formControlName="comments" class="form-control input-field form-field__input"
                        name="comments"></textarea>
                </div>

                <!-- Weather Conditions -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="weatherConditions" [class.form-field__label_full]="weatherConditions.value"
                        class="form-field__label">
                        Weather Conditions
                    </label>
                    <input formControlName="weatherConditions" type="text"
                        class="form-control input-field form-field__input" name="weatherConditions" />
                </div>

                <!-- Daily Progress Achieved -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="dailyProgressAchieved" [class.form-field__label_full]="dailyProgressAchieved.value"
                        class="form-field__label">
                        Daily Progress Achieved
                    </label>
                    <textarea formControlName="dailyProgressAchieved" class="form-control input-field form-field__input"
                        name="dailyProgressAchieved"></textarea>
                </div>

                <!-- Next 24 Hour Planning -->
                <div class="form-field mb-4 d-flex align-items-center">
                    <label for="next24HourPlanning" [class.form-field__label_full]="next24HourPlanning.value"
                        class="form-field__label">
                        Next 24 Hour Planning
                    </label>
                    <textarea formControlName="next24HourPlanning" class="form-control input-field form-field__input"
                        name="next24HourPlanning"></textarea>
                </div>

                <div class="wrap-logo-inputs ml-0">
                    <div class="wrap-logo-inputs__item">
                        <div class="wrap-logo-inputs__label">Company Logo </div>
                        <label class="wrap-logo-inputs__input logo-input">
                            <input (change)="imagesPreview($event, companyLogo)" type="file" name="companyLogo"
                                accept="image/*" #clientFileInput style="display: none;">
                            <img class="preview-image" [src]="companyLogo.value.link"
                                style="object-fit: contain; max-width: 70%; max-height: 70%">

                            <div *ngIf="!companyLogo.value.link" class="logo-input__icon">
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0)">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M26.2537 3.75H3.75366C3.25638 3.75 2.77947 3.94754 2.42784 4.29917C2.07621 4.65081 1.87866 5.12772 1.87866 5.625V24.375C1.87866 24.8723 2.07621 25.3492 2.42784 25.7008C2.77947 26.0525 3.25638 26.25 3.75366 26.25H26.2537C26.7509 26.25 27.2279 26.0525 27.5795 25.7008C27.9311 25.3492 28.1287 24.8723 28.1287 24.375V5.625C28.1287 5.12772 27.9311 4.65081 27.5795 4.29917C27.2279 3.94754 26.7509 3.75 26.2537 3.75ZM3.75366 1.875C2.7591 1.875 1.80527 2.27009 1.10201 2.97335C0.39875 3.67661 0.00366211 4.63044 0.00366211 5.625V24.375C0.00366211 25.3696 0.39875 26.3234 1.10201 27.0266C1.80527 27.7299 2.7591 28.125 3.75366 28.125H26.2537C26.7461 28.125 27.2338 28.028 27.6887 27.8395C28.1437 27.6511 28.5571 27.3749 28.9053 27.0266C29.2535 26.6784 29.5298 26.265 29.7182 25.8101C29.9067 25.3551 30.0037 24.8675 30.0037 24.375V5.625C30.0037 4.63044 29.6086 3.67661 28.9053 2.97335C28.2021 2.27009 27.2482 1.875 26.2537 1.875H3.75366Z"
                                            fill="#CAD2D5" />
                                        <path
                                            d="M19.9649 14.3362C20.1042 14.1973 20.2836 14.1057 20.4777 14.0744C20.6719 14.0432 20.871 14.0737 21.0468 14.1618L28.1287 17.8124V26.2499H1.87866V22.4999L6.83991 18.0862C6.99316 17.9335 7.1945 17.8385 7.4098 17.8173C7.62509 17.7961 7.84109 17.85 8.02116 17.9699L13.0087 21.2943L19.9649 14.338V14.3362Z"
                                            fill="#CAD2D5" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M8.44116 13.125C8.8105 13.125 9.17623 13.0523 9.51746 12.9109C9.85869 12.7696 10.1687 12.5624 10.4299 12.3012C10.6911 12.0401 10.8982 11.73 11.0396 11.3888C11.1809 11.0476 11.2537 10.6818 11.2537 10.3125C11.2537 9.94316 11.1809 9.57743 11.0396 9.2362C10.8982 8.89497 10.6911 8.58493 10.4299 8.32376C10.1687 8.0626 9.85869 7.85543 9.51746 7.71409C9.17623 7.57275 8.8105 7.5 8.44116 7.5C7.69524 7.5 6.97987 7.79632 6.45242 8.32376C5.92498 8.85121 5.62866 9.56658 5.62866 10.3125C5.62866 11.0584 5.92498 11.7738 6.45242 12.3012C6.97987 12.8287 7.69524 13.125 8.44116 13.125Z"
                                            fill="#CAD2D5" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="30" height="30" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span class="logo-input__text">{{companyLogo.value.name || 'Upload an image'}} </span>

                        </label>
                    </div>

                </div>

            </form>

        </mat-tab>

        <mat-tab label="TimeLogs">
            <div class="p-2 card m-2">
                <!-- Add Time Log Text -->
                <span class="add-log" (click)="toggleForm()">Add Time Log</span>

                <!-- Conditionally display form fields -->

                <div [hidden]="!showForm">
                    <form class="d-flex flex-column  bg-white w-100 form-border" [formGroup]="timeLogForm">

                        <div class="form-field mb-4 d-flex align-items-center ">
                            <label class="form-field__label required">
                                Next 24 Hour Planning
                            </label>
                            <input type="date" formControlName="time" required
                                class="form-control input-field form-field__input" />
                        </div>


                        <div class="form-field mb-4 d-flex align-items-center">
                            <label class="form-field__label required">
                                Description
                            </label>
                            <textarea formControlName="description" required
                                class="form-control input-field form-field__input"></textarea>
                        </div>

                        <div class="form-field mb-4 d-flex align-items-center">
                            <label class="form-field__label">
                                Comment
                            </label>
                            <textarea formControlName="comment"
                                class="form-control input-field form-field__input"></textarea>
                        </div>

                        <div class="form-actions">
                            <button [disabled]="!timeLogForm.valid" mat-raised-button color="primary"
                                (click)="saveTimeLog()">ADD</button>
                            <button mat-raised-button color="warn" (click)="cancel()">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="p-2 card m-2" *ngIf="timeLogs.length ">
                <!-- Display Time Logs in Table -->
                <table class="time-log-table" *ngIf="timeLogs.length > 0">
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Description</th>
                            <th>Comment</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let log of timeLogs">
                            <td>{{ log.time }}</td>
                            <td>{{ log.description }}</td>
                            <td>{{ log.comment }}</td>
                            <td>
                                <!-- Cross button to remove time log -->
                                <button mat-button (click)="deleteTimeLog(log.id)">X</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-tab>
    </mat-tab-group>




</div>