<div class="popup-header w-100 d-flex">
    <p class="w-100">
      Email
    </p>
    <button 
    mat-button 
    mat-raised-button 
    color="primary" 
    (click)="sendEmail()"
   
    [disabled]="isEmailLoading$.value ||  !selectedUsers?.length && !selectedEmails?.length">
    Send
    <span *ngIf="isEmailLoading$ | async">
        <mat-spinner [diameter]="20"></mat-spinner>
    </span>
  </button>
  
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
  </div>


  <div class="p-2 m-2 card bg-white form-border" 
  style="position: relative;">
  <!-- Tempalte -->
  <div class="form-field mb-4 d-flex align-items-center">
      <label 
          class="form-field__label required">
        Project Members
      </label>
      <mat-select [(ngModel)]="selectedUsers" multiple required class="form-control input-field report-name-input form-field__input">
        <mat-option *ngFor="let user of projectUsers" [value]="user.uid">
          {{ user.firstName && user.lastName ? (user.firstName + ' ' + user.lastName) : user.displayName }}
        </mat-option>
      </mat-select>
  </div>


  <div class="form-field mb-4 d-flex align-items-center">
    <label 
        class="form-field__label required">
        Add other emails
    </label>
<mat-form-field class="w-100 input-field report-name-input form-field__input">
  <mat-chip-list #chipList>
    <!-- Display added emails as removable tags -->
    <mat-chip *ngFor="let email of selectedEmails" [removable]="true" (removed)="removeEmail(email)">
        {{ email }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
  
      <!-- Input for manually adding emails -->
      <input
        matInput
        placeholder="Add emails (comma or enter to separate)"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addEmails($event)">
    </mat-chip-list>
  </mat-form-field>
  
  </div> 

</div>




