import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BackendService } from '../../services/backend.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UiService } from '../../services/ui.service';
import * as moment from 'moment';
import { GeneralService } from '../../services/general.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-daily-reports-initials',
  templateUrl: './daily-reports-initials.component.html',
  styleUrls: ['./daily-reports-initials.component.scss']
})
export class DailyReportsInitialsComponent implements OnInit {
  public projectId: string;
  public projects;
  projectUsers;
  projectTemplateSubscribe;
  templates: any[];
  asset;
  showForm = false;
  timeLog = {
    time: '',
    description: '',
    comment: ''
  };
  maxDate: string = new Date().toISOString().split('T')[0];
  public isUpdateInitialsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public initialsForm = new FormGroup({
    templateId: new FormControl('', Validators.required),
    reportNumber: new FormControl('', Validators.required),
    projectName: new FormControl('', Validators.required),
    projectLocation: new FormControl(''),
    reportDate: new FormControl(new Date(), Validators.required),
    clientName: new FormControl(''),
    projectDescription: new FormControl(''),
    revision: new FormControl(''),
    approver: new FormControl('', Validators.required),
    reviewer: new FormControl('', Validators.required),
    workPermitNo: new FormControl(''),
    workSite: new FormControl(''),
    location: new FormControl(''),
    personnelName: new FormControl(''),
    personnelCompany: new FormControl(''),
    position: new FormControl(''),
    comments: new FormControl(''),
    weatherConditions: new FormControl(''),
    dailyProgressAchieved: new FormControl(''),
    next24HourPlanning: new FormControl(''),
    companyLogo: new FormGroup({
      name: new FormControl(''),
      file: new FormControl(null),
      link: new FormControl(''),
    }),
    id: new FormControl('')
  });

  public timeLogForm = new FormGroup({
    time: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    comment: new FormControl(''),
  });

  // Getters for each form control
  get templateId(): AbstractControl { return this.initialsForm.get('templateId'); }
  get reportNumber(): AbstractControl { return this.initialsForm.get('reportNumber'); }
  get projectName(): AbstractControl { return this.initialsForm.get('projectName'); }
  get projectLocation(): AbstractControl { return this.initialsForm.get('projectLocation'); }
  get reportDate(): AbstractControl { return this.initialsForm.get('reportDate'); }
  get clientName(): AbstractControl { return this.initialsForm.get('clientName'); }
  get projectDescription(): AbstractControl { return this.initialsForm.get('projectDescription'); }
  get revision(): AbstractControl { return this.initialsForm.get('revision'); }
  get approver(): AbstractControl { return this.initialsForm.get('approver'); }
  get reviewer(): AbstractControl { return this.initialsForm.get('reviewer'); }
  get workPermitNo(): AbstractControl { return this.initialsForm.get('workPermitNo'); }
  get workSite(): AbstractControl { return this.initialsForm.get('workSite'); }
  get location(): AbstractControl { return this.initialsForm.get('location'); }
  get personnelName(): AbstractControl { return this.initialsForm.get('personnelName'); }
  get personnelCompany(): AbstractControl { return this.initialsForm.get('personnelCompany'); }
  get position(): AbstractControl { return this.initialsForm.get('position'); }
  get comments(): AbstractControl { return this.initialsForm.get('comments'); }
  get weatherConditions(): AbstractControl { return this.initialsForm.get('weatherConditions'); }
  get dailyProgressAchieved(): AbstractControl { return this.initialsForm.get('dailyProgressAchieved'); }
  get next24HourPlanning(): AbstractControl { return this.initialsForm.get('next24HourPlanning'); }
  get companyLogo(): FormGroup { return this.initialsForm.get('companyLogo') as FormGroup; }
  get companyLogoName(): AbstractControl { return this.companyLogo.get('name'); }
  get companyLogoFile(): AbstractControl { return this.companyLogo.get('file'); }
  get companyLogoLink(): AbstractControl { return this.companyLogo.get('link'); }
  get id(): AbstractControl { return this.initialsForm.get('id'); }

  constructor(
    public backendService: BackendService,
    private uiService: UiService,
    private generalService: GeneralService,
    public dialogRef: MatDialogRef<DailyReportsInitialsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.projectId = this.data.projectId;
    if (!this.data.id) {
      this.initialsForm.patchValue({
        projectName: this.uiService.project?.name
      })
    } else {
      this.getDailyReportById(this.projectId, this.data.id);
    }
  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '0', right: '0' });
    this.getTemplates(this.projectId);
    this.getMembers();
  }


  imagesPreview(event, form: AbstractControl): void {
    if (event.target.files && event.target.files[0]) {
      let imageFile;
      const reader = new FileReader();
      reader.onload = (_event: any) => {
        imageFile = {
          link: _event.target.result,
          file: event.srcElement.files[0],
          name: event.srcElement.files[0].name
        };
        form.setValue(imageFile);

      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  revisedProjectInitials(data) {
    return this.backendService.revisedProjectInitials$(data, this.projectId)
  }


  reviewerMembers = [];
  approverMembers = [];
  public getMembers(): void {
    const projectPeoples = this.uiService.project?.people;
    const keys = Object.keys(projectPeoples);
    this.backendService.getProjectPeople(keys).then(users => {
      this.projectUsers = users;
      /* Default add reviewer,approver to initials window */
      let reviewer = [];
      let approver = [];
      keys.forEach(key => {
        if (projectPeoples[key].role == 'reviewer') {
          reviewer.push({
            id: key,
            name: this.getRoleName(key)
          });
        }
        if (projectPeoples[key].role == 'approver') {
          approver.push({
            id: key,
            name: this.getRoleName(key)
          });
        }
      });
      this.reviewerMembers = reviewer;
      this.approverMembers = approver.concat(reviewer);


    })
  }

  public updateProjectDailyReport(): void {
    let initialsData = this.initialsForm.value;
    this.isUpdateInitialsLoading$.next(true)
    combineLatest([
      this.backendService.saveLogos(initialsData.companyLogo)
    ]).pipe(
      switchMap(([companyLogo]) => {
        if (companyLogo) {
          initialsData.companyLogo = companyLogo;
        }
        if (initialsData.reportDate) {
          initialsData.reportDate = moment(initialsData.reportDate).format('DD-MMM-YYYY')
        }
        return this.backendService.updateProjectDailyReports$({ ...this.initialsForm.value, timeLogs: [...this.timeLogs] }, this.projectId)
      })
    ).subscribe(response => {
      this.isUpdateInitialsLoading$.next(false)
      this.initialsForm.reset();
      this.dialogRef.close();
    }, error => {
      this.isUpdateInitialsLoading$.next(false)
    });
  }

  getDailyReportById(projectId, id) {
    this.backendService.getDailyReportById(projectId, id).subscribe(response => {
      this.initialsForm.patchValue({
        ...response,
        reportDate: response.reportDate ? new Date(response.reportDate) : null, // Ensure valid date
      });
      this.timeLogs = response.timeLogs || []
    });
  }

  public getTemplates(projectId: string): void {
    this.projectTemplateSubscribe = this.backendService.getDailyReportTemplates(projectId).subscribe(response => {
      this.templates = response;
      console.log(this.templates)
    });
  }

  getRoleName(id) {
    const user = this.projectUsers.find(o => o && o.uid == id)
    return user ? (user.fistName && user.lastName) ? user.fistName + ' ' + user.lastName : user.displayName : " ";
  }

  getReadOnlyForCurrentUser(project): boolean {
    const projectId = this.projectId;
    return this.generalService.getReadOnlyForCurrentUser(this.projects.find(o => o.id == projectId));
  }

  ngOnDestroy(): void {
    this.projectTemplateSubscribe.unsubscribe();
  }


  toggleForm() {
    this.showForm = !this.showForm;
  }

  timeLogs = [];
  saveTimeLog() {
    const newTimeLog = {
      id: uuidv4(),
      ...this.timeLogForm.value
    };
    this.timeLogs.push(newTimeLog);  // Add the new time log to the array
    this.resetForm();  // Reset the form after saving
  }

  cancel() {
    this.resetForm();  // Reset the form if canceled
    this.showForm = false; // Hide the form
  }

  resetForm() {
    this.timeLogForm.reset(); // Clear the form

  }

  deleteTimeLog(id: number) {
    this.timeLogs = this.timeLogs.filter(log => log.id !== id); // Remove the time log with the specified id
  }

  copy() {
    const report = this.data.reports[0];
    delete(report.approveDate);
    delete(report.reviewDate);
    this.initialsForm.patchValue({...report , id:'',createdTime:new Date(),sendForReview:false,isReviewed:false,isApproved:false});
  }

}
